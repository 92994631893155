/* eslint-disable */
import axios from 'axios';
import { showAlert } from './alerts';

export const createTraining = async (data) => {
  try {
    const res = await axios.post(`/api/v1/trainings`,
      data
    );
    if (res.data.status === 'success') {
      showAlert('success', 'Poprawnie dodano trening(i)');
      
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
    window.setTimeout(() => {
      location.reload(true);
    }, 2500);
  }
};

  export const updateTraining = async (data,id) => {
    try {
      const res = await axios.patch(`/api/v1/trainings/${id}`,
        data
      );
      if (res.data.status === 'success') {
        showAlert('success', 'Poprawnie zaktualizowano trening');
        
      }
    } catch (err) {
      showAlert('error', err.response.data.message);
      window.setTimeout(() => {
        location.reload(true);
      }, 2500);
    }
  };

  export const deleteTraining = async (id) => {
    try {
      
      const res = await axios.delete(`/api/v1/trainings/${id}`);
      if (res.status === 204) {
        showAlert('success', 'Poprawnie usunięto trening');
        window.setTimeout(() => {
          location.assign(`/trainingCalendar`);
        }, 1500);
      }
    }catch(err){
      showAlert('error', err.response.data.message);
      
    }
  }