/* eslint-disable */
import axios from 'axios';
import { showAlert } from './alerts';

// export const addGroup = async (data) => {
//     try {
//       const res = await axios({
//         method: 'POST',
//         url: `/api/v1/groups`,
//         data
//       });
//       if (res.data.status === 'success') {
//         showAlert('success', `Poprawnie dodano nową grupę o nazwie: ${data.name}`);
//         window.setTimeout(() => {
//           location.reload(true);
//         }, 1500);
//       }
//     } catch (err) {
//       showAlert('error', err.response.data.message);
//       window.setTimeout(() => {
//         location.reload(true);
//       }, 1500);
//     }
//   };

export const addGroup = async data => {
  try {
    const res = await axios.post(`/api/v1/groups`, data);
    if (res.data.status === 'success') {
      showAlert(
        'success',
        `Poprawnie dodano nową grupę o nazwie: ${data.name}`
      );
      window.setTimeout(() => {
        location.reload(true);
      }, 1500);
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
    window.setTimeout(() => {
      location.reload(true);
    }, 1500);
  }
};
// export const deleteGroup = async (id) => {

//   try {
//     const res = await axios({
//       method: 'DELETE',
//       url: `/api/v1/groups/${id}`
//     });
//     if (res.status === 204) {
//       showAlert('success', `Poprawnie usunięto grupę`);
//       window.setTimeout(() => {
//         location.reload(true);
//       }, 1500);
//     }
//   } catch (err) {
//     showAlert('error', err.response.data.message);
//     window.setTimeout(() => {
//       location.reload(true);
//     }, 1500);
//   }
// };

export const deleteGroup = async id => {
  try {
    const res = await axios.delete(`/api/v1/groups/${id}`);
    if (res.status === 204) {
      showAlert('success', `Poprawnie usunięto grupę`);
      window.setTimeout(() => {
        location.reload(true);
      }, 1500);
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
    window.setTimeout(() => {
      location.reload(true);
    }, 1500);
  }
};

// export const updateGroup = async (data, id) => {

//   try {
//     const res = await axios({
//       method: 'PATCH',
//       url: `/api/v1/groups/${id}`,
//       data
//     });
//     if (res.data.status === 'success') {
//       showAlert('success', 'Zaktualizowano dane');
//       window.setTimeout(() => {
//         location.reload(true);
//       }, 1500);
//     }
//   } catch (err) {
//     showAlert('error', err.response.data.message);
//     window.setTimeout(() => {
//       location.reload(true);
//     }, 1500);
//   }
// };

export const updateGroup = async (data, id) => {
  try {
    const res = await axios.patch(`/api/v1/groups/${id}`, data);
    if (res.data.status === 'success') {
      showAlert('success', 'Zaktualizowano dane');
      window.setTimeout(() => {
        location.reload(true);
      }, 1500);
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
    window.setTimeout(() => {
      location.reload(true);
    }, 1500);
  }
};
