/* eslint-disable */
import { Calendar } from 'fullcalendar';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import axios from 'axios';
import plLocale from '@fullcalendar/core/locales/pl';
import { Polish } from 'flatpickr/dist/l10n/pl.js';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import { showAlert, showAlertInfo, hideAlert } from './alerts';
export const generateGroupReport = response => {
  const resources = [];
  const events = [];
  // Przetwarzanie danych z odpowiedzi API i dodawanie ich do harmonogramu
  response.data.results.forEach(function(player, index) {
    const resourceId = `resource-${index}`;
    const playerName = `${player.name} ${player.surName}`;

    // Dodawanie zasobów (resources)
    resources.push({ id: resourceId, title: playerName });

    for (const [date, status] of Object.entries(player.dailyReport)) {
      // Przetwarzanie daty rozpoczęcia i zakończenia
      const start = date;
      const end = new Date(
        new Date(date).getTime() + 2 * 60 * 60 * 1000
      ).toISOString();
      let statusColor = '';
      if (status === 'obecny') {
        statusColor = 'green';
      } else if (status === 'nieobecny') {
        statusColor = 'red';
      } else {
        statusColor = 'grey';
      }
      // Dodawanie wydarzeń (events)
      events.push({
        //id: `resource-${index}`,
        title: status,
        start: start,
        end: end,
        resourceId: resourceId,
        backgroundColor: statusColor
      });
    }
  });
  let earliestDate = null;
  for (const event of events) {
  if (!earliestDate || new Date(event.start) < new Date(earliestDate)) {
    earliestDate = event.start;
  }
}
  // Inicjalizacja i konfiguracja harmonogramu FullCalendar
  const calendarEl = document.getElementById('attendance-report');
  const calendar = new Calendar(calendarEl, {
    plugins: [resourceTimelinePlugin],
    initialView: 'resourceTimelineMonth',
    resourceAreaHeaderContent: 'Imię Nazwisko',
    resources: resources,
    events: events,
    timeZone: 'UTC',
    locale: plLocale,
    resourceAreaWidth: 164,
    height:800,
    initialDate: earliestDate,
    

    eventContent: function(arg) {
      return {
        html:
          '<div class="event-time">' +
          arg.timeText +
          '</div>' +
          '<div class="event-title">' +
          arg.event.title +
          '</div>'
      };
    }
  });
  calendar.render();

  const calendarEl2 = document.getElementById('training-report');

  const events2 = response.data.trainings.trainings.map(training => ({
    title: training.name,
    start: new Date(training.startDate),
    end: new Date(training.endDate),
    description: training.description,
    id: training.id
  }));

  const calendar2 = new Calendar(calendarEl2, {
    plugins: [dayGridPlugin, timeGridPlugin],
    defaultView: 'timeGridWeek',
    events: events2,
    locale: plLocale,
    initialDate: earliestDate,
    eventContent: function(arg) {
      let event = arg.event;
    
      let content = document.createElement('div');
      content.classList.add('custom-event-content'); // Dodaj dowolne klasy stylów

      let timeElement = document.createElement('div');
      timeElement.innerText = arg.timeText;
      timeElement.classList.add('fc-event-time');

      let titleElement = document.createElement('div');
      titleElement.innerText = event.title;
      titleElement.classList.add('fc-event-title'); // Dodaj klasy stylów dla tytułu
    
      let descriptionElement = document.createElement('div');
      descriptionElement.innerText = event.extendedProps.description;
      descriptionElement.classList.add('event-description'); // Dodaj klasy stylów dla opisu
    
      
      content.appendChild(timeElement);
      content.appendChild(titleElement);
      content.appendChild(descriptionElement);
    
      return { domNodes: [content] };
    }
  });
  calendar2.render();
  const labels = response.data.results.map(uczestnik => `${uczestnik.name} ${uczestnik.surName}`);
const obecnosc = response.data.results.map(uczestnik => (uczestnik.totalObecny / uczestnik.totalTrainings) * 100);
  
  const ctx = document.getElementById("attendence-chart").getContext("2d");

  if (window.myChart) {
    window.myChart.destroy();
  }
  
  window.myChart = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: labels,
                datasets: [{
                    label: 'Procentowa obecność w wybranym przedziale czasowym',
                    data: obecnosc,
                    backgroundColor: 'rgba(75, 192, 192, 0.2)',
                    borderColor: 'rgba(75, 192, 192, 1)',
                    borderWidth: 1
                }]
            },
            options: {
                scales: {
                    y: {
                        beginAtZero: true,
                        max: 100,
                        title: {
                            display: true,
                            text: 'Procent obecności (%)'
                        }
                    }
                }
            }
        });

};

export const generatePDF = () => {
  const divToConvert = document.getElementById('to-print');
    const select = document.getElementById('groupSelect');
    const selectedOption = select.options[select.selectedIndex].textContent;  
    const pdfOptions = {
      margin: 40, // Dostosuj marginesy, jeśli jest to konieczne
      filename: 'report.pdf',
      image: { type: 'jpeg', quality: 1 }, // JPEG jest używany do zachowania jakości obrazu
      html2canvas: { scale: 1 }, // Zwiększ skalowanie, aby uwzględnić cały div
      jsPDF: {
        unit: 'px',
        format: [3300, 1100],
        orientation: 'landscape' // Możesz zmienić na landscape, jeśli wymagane
      }
    };
    
    const pagebreak = {
      mode: 'legacy'
    };
    html2pdf()
      .from(divToConvert)
      .set(pdfOptions, pagebreak)
      .toPdf()
      .get('pdf')
      .then(pdf => {
        var totalPages = pdf.internal.getNumberOfPages();

        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          pdf.setFontSize(30);
          pdf.setTextColor(150);
          pdf.text(15, 25, `Dziennik treningowy KJ AZS Opole. Grupa: ${selectedOption}. `);
          
        } 
  }).save();
}

export const sendPDF = async (email) => {
  const divToConvert = document.getElementById('to-print');
  const select = document.getElementById('groupSelect');
  const selectedOption = select.options[select.selectedIndex].textContent;
  showAlertInfo('info', 'Wysyłanie w toku...');
  const pdfOptions = {
    margin: 40,
    filename: 'report.pdf',
    image: { type: 'jpeg', quality: 1 },
    html2canvas: { scale: 1 },
    jsPDF: {
      unit: 'px',
      format: [3300, 1200],
      orientation: 'landscape',
    },
  };

  const pagebreak = {
    mode: 'legacy',
  };

  const pdfBlob = await html2pdf()
    .from(divToConvert)
    .set(pdfOptions, pagebreak)
    .toPdf()
    .get('pdf')
    .then((pdf) => {
      const totalPages = pdf.internal.getNumberOfPages();

      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);
        pdf.setFontSize(30);
        pdf.setTextColor(150);
        pdf.text(15, 25, `Dziennik treningowy KJ AZS Opole. Grupa: ${selectedOption}.`);
      }

      return pdf.output('blob');
    });

  const formData = new FormData();
  formData.append('email', email);
  formData.append('file', pdfBlob, 'report.pdf');

  try {
    // Prześlij dane na serwer
    const response = await axios.post('/api/v1/groups/attendence/group/sendreport', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (response.data.status === 'success') {
      hideAlert();
      showAlert('success', 'Wysłano e-mail');
     
    }
  } catch (error) {
    hideAlert();
    showAlert('error', err.response.data.message);
    console.error('Błąd podczas przesyłania pliku PDF:', error);
  }
};