/* eslint-disable */
import axios from 'axios';
import { showAlert } from './alerts';

export const updateSettings = async (data, type) => {
    try {
      const res = await axios({
        method: 'PATCH',
        url: `/api/v1/users/updateme`,
        data
      });
      if (res.data.status === 'success') {
        showAlert('success', 'Zaktualizowano dane');
        
      }
    } catch (err) {
      showAlert('error', err.response.data.message);
      window.setTimeout(() => {
        location.reload(true);
      }, 1500);
    }
  };

  

 