/* eslint-disable */
//import '@babel/polyfill';
import $ from 'jquery';
globalThis.jQuery = $;
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import flatpickr from 'flatpickr';
import { Calendar } from 'fullcalendar';
import plLocale from '@fullcalendar/core/locales/pl';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import interactionPlugin from '@fullcalendar/interaction';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import { generateGroupReport, generatePDF, sendPDF } from './report';
import { showAlert } from './alerts';
import { Polish } from 'flatpickr/dist/l10n/pl.js';
import { login } from './login';
import { logout, resetPassword, changePassword, signup } from './login';
import {
  updatePlayer,
  createPlayer,
  deletePlayer,
  attendaceUpdate,
  uploadPlayerPhoto,
  addChild
} from './player';
import { updateSettings } from './updateSettings';
import { addGroup, deleteGroup, updateGroup } from './groups';
import { createTraining, updateTraining, deleteTraining } from './training';
import autoComplete from '@tarekraafat/autocomplete.js';
import axios from 'axios';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';

const loginForm = document.querySelector('#form-login');
const logOutBtn = document.querySelector('#logout-btn');

$(document).ready(function() {
  $('#login-link').click(function(e) {
    e.preventDefault();
    $('#login-form').show();
    $('#register-form').hide();
    $('#forgot-password-form').hide();
    $(this).addClass('active');
    $('#register-link, #forgot-password-link').removeClass('active');
  });

  $('#register-link').click(function(e) {
    e.preventDefault();
    $('#login-form').hide();
    $('#register-form').show();
    $('#forgot-password-form').hide();
    $(this).addClass('active');
    $('#login-link, #forgot-password-link').removeClass('active');
  });

  $('#forgot-password-link').click(function(e) {
    e.preventDefault();
    $('#login-form').hide();
    $('#register-form').hide();
    $('#forgot-password-form').show();
    $(this).addClass('active');
    $('#login-link, #register-link').removeClass('active');
  });
});
if (loginForm) {
  loginForm.addEventListener('submit', e => {
    e.preventDefault();
    const email = document.getElementById('login-email').value;
    const password = document.getElementById('login-password').value;
    login(email, password);
  });
}
if (logOutBtn) {
  logOutBtn.addEventListener('click', logout);
}
if (window.location.pathname.startsWith('/player')) {
  document.addEventListener('DOMContentLoaded', function() {
    let editing = false;
    const nameValue = document.getElementById('name-value');
    const surNameValue = document.getElementById('surName-value');
    const dateOfBirthValue = document.getElementById('dateOfBirth-value');
    const addressValue = document.getElementById('address-value');
    const parentPhoneValue = document.getElementById('parentPhone-value');
    const playerPhoneValue = document.getElementById('playerPhone-value');
    const peselValue = document.getElementById('pesel-value');
    const beltValue = document.getElementById('belt-value');
    // Dodaj pozostałe elementy DOM

    const editButton = document.getElementById('edit-button');
    const saveButton = document.getElementById('save-button');

    editButton.addEventListener('click', function() {
      enableEditing();
    });

    saveButton.addEventListener('click', function() {
      saveChanges();
    });

    function enableEditing() {
      editing = true;
      nameValue.innerHTML =
        '<input type="text" id="name-input" value="' +
        nameValue.textContent +
        '">';
      surNameValue.innerHTML =
        '<input type="text" id="surName-input" value="' +
        surNameValue.textContent +
        '">';
      dateOfBirthValue.innerHTML =
        '<input type="text" id="dateOfBirth-input" value="' +
        dateOfBirthValue.textContent +
        '">';
      addressValue.innerHTML =
        '<input type="text" id="address-input" value="' +
        addressValue.textContent +
        '">';
      parentPhoneValue.innerHTML =
        '<input type="text" id="parentPhone-input" value="' +
        parentPhoneValue.textContent +
        '">';
      playerPhoneValue.innerHTML =
        '<input type="text" id="playerPhone-input" value="' +
        playerPhoneValue.textContent +
        '">';
      peselValue.innerHTML =
        '<input type="text" id="pesel-input" value="' +
        peselValue.textContent +
        '">';
      beltValue.innerHTML =
        '<input type="text" id="belt-input" value="' +
        beltValue.textContent +
        '">';
      // Dodaj pozostałe pola edycji

      editButton.style.display = 'none';
      saveButton.style.display = 'block';
    }

    function saveChanges() {
      const inputDate = document.getElementById('dateOfBirth-input').value;
      function formatDate(inputDate) {
        // Parsujemy datę w formacie DD.MM.RRRR
        const parts = inputDate.split('.');
        if (parts.length !== 3) {
          throw new Error('Niepoprawny format daty');
        }

        const day = parseInt(parts[0], 10);
        const month = parseInt(parts[1], 10) - 1; // Miesiące są numerowane od 0 do 11
        const year = parseInt(parts[2], 10);

        // Tworzymy obiekt Date
        const date = new Date(year, month, day);

        // Ustalamy strefę czasową na UTC
        date.setUTCHours(0, 0, 0, 0);

        // Stała końcówka
        const endString = 'T00:00:00.000+00:00';

        // Sklejamy datę z końcówką
        const formattedDate = date.toISOString().split('T')[0] + endString;

        return formattedDate;
      }
      const formattedDate = formatDate(inputDate);
      const updatedData = {};

      // Sprawdź, czy każde pole jest wypełnione, a następnie dodaj je do updatedData
      const nameInput = document.getElementById('name-input').value;
      if (nameInput) updatedData.name = nameInput;

      const surNameInput = document.getElementById('surName-input').value;
      if (surNameInput) updatedData.surName = surNameInput;

      // Analogicznie sprawdź pozostałe pola
      const addressInput = document.getElementById('address-input').value;
      if (addressInput) updatedData.address = addressInput;

      const dateOfBirthInput = document.getElementById('dateOfBirth-input')
        .value;
      if (dateOfBirthInput) updatedData.dateOfBirth = formattedDate;

      const parentPhoneInput = document.getElementById('parentPhone-input')
        .value;
      if (parentPhoneInput) updatedData.parentPhone = parentPhoneInput;

      const playerPhoneInput = document.getElementById('playerPhone-input')
        .value;
      if (playerPhoneInput) updatedData.playerPhone = playerPhoneInput;

      const peselInput = document.getElementById('pesel-input').value;
      if (peselInput) updatedData.pesel = peselInput;

      const beltInput = document.getElementById('belt-input').value;
      if (beltInput) updatedData.belt = beltInput;

      // Wyślij żądanie PATCH do zapisania danych
      const url = window.location.href;
      const segments = url.split('/');
      const playerId = segments.pop();
      updatePlayer(updatedData, playerId);

      nameValue.innerHTML =
        '<span id="name-value">' + updatedData.name + '</span>';
      surNameValue.innerHTML =
        '<span id="surName-value">' + updatedData.surName + '</span>';
      dateOfBirthValue.innerHTML =
        '<span id="dateOfBirth-value">' + inputDate + '</span>';
      addressValue.innerHTML =
        '<span id="address-value">' + updatedData.address + '</span>';
      parentPhoneValue.innerHTML =
        '<span id="parentPhone-value">' + updatedData.parentPhone + '</span>';
      playerPhoneValue.innerHTML =
        '<span id="playerPhone-value">' + updatedData.playerPhone + '</span>';
      peselValue.innerHTML =
        '<span id="pesel-value">' + updatedData.pesel + '</span>';
      beltValue.innerHTML =
        '<span id="belt-value">' + updatedData.belt + '</span>';
      // Zaktualizuj pozostałe pola

      editing = false;

      editButton.style.display = 'block';
      saveButton.style.display = 'none';
    }
  });
}
if (window.location.pathname.startsWith('/me')) {
  document.addEventListener('DOMContentLoaded', function() {
    let editing = false;
    const nameValue = document.getElementById('userName-value');
    const surNameValue = document.getElementById('userSurName-value');
    const emailValue = document.getElementById('userEmail-value');

    const editButton = document.getElementById('edit-user-button');
    const saveButton = document.getElementById('save-user-button');

    editButton.addEventListener('click', function() {
      enableEditing();
    });

    saveButton.addEventListener('click', function() {
      saveChanges();
    });

    function enableEditing() {
      editing = true;
      nameValue.innerHTML =
        '<input type="text" id="userName-input" value="' +
        nameValue.textContent +
        '">';
      surNameValue.innerHTML =
        '<input type="text" id="userSurName-input" value="' +
        surNameValue.textContent +
        '">';
      emailValue.innerHTML =
        '<input type="text" id="userEmail-input" value="' +
        emailValue.textContent +
        '">';

      editButton.style.display = 'none';
      saveButton.style.display = 'block';
    }

    function saveChanges() {
      const updatedData = {
        name: document.getElementById('userName-input').value,
        surName: document.getElementById('userSurName-input').value,
        email: document.getElementById('userEmail-input').value
      };

      updateSettings(updatedData, 'updateme');

      nameValue.innerHTML =
        '<span id="name-value">' + updatedData.name + '</span>';
      surNameValue.innerHTML =
        '<span id="surName-value">' + updatedData.surName + '</span>';
      emailValue.innerHTML =
        '<span id="email-value">' + updatedData.email + '</span>';

      editing = false;

      editButton.style.display = 'block';
      saveButton.style.display = 'none';
    }
  });
  const userPasswordForm = document.querySelector('#form-user-password');

  userPasswordForm.addEventListener('submit', e => {
    e.preventDefault();
    const passwordCurrent = document.getElementById('passwordCurrent').value;
    const password = document.getElementById('password').value;
    const passwordConfirm = document.getElementById('passwordConfirm').value;
    updateSettings(
      { passwordCurrent, password, passwordConfirm },
      'updatemypassword'
    );
  });
}

const addGroupForm = document.querySelector('#add-group-form');
if (addGroupForm) {
  addGroupForm.addEventListener('submit', e => {
    e.preventDefault();
    const name = document.getElementById('groupName').value;
    const coachName = document.getElementById('coachGroupName').value;

    addGroup({ name, coachName });
  });
}
const groupTableOverviewDelete = document.getElementById(
  'group-table-overview-delete'
);

const groupTableOverview = document.getElementById('group-table-overview');
if (groupTableOverview || groupTableOverviewDelete) {
  const removePlayerButtons = document.querySelectorAll('.remove-player-btn');

  removePlayerButtons.forEach(function(button) {
    button.addEventListener('click', function() {
      const playerId = button.getAttribute('data-player-id');
      const confirmDeleteBtn = document.getElementById('confirmDeleteBtn');
      confirmDeleteBtn.addEventListener('click', function() {
        const data = { group: '64b7e70fe2d0cc4715b59fa2' };
        groupTableOverview
          ? updatePlayer(data, playerId)
          : deletePlayer(playerId);

        // Ukrywamy modal po kliknięciu przycisku "Usuń"
        const modalElement = document.getElementById('confirmDeleteModal');
        const modal = bootstrap.Modal.getInstance(modalElement);
        modal.hide();
      });
    });
  });
}

const addPlayerToGroup = document.getElementById('add-player-to-group');
if (addPlayerToGroup) {
  addPlayerToGroup.addEventListener('submit', e => {
    e.preventDefault();
    const playerId = addPlayerToGroup.playerId.value;
    const groupId = { group: addPlayerToGroup.groupId.value };
    updatePlayer(groupId, playerId);
  });
}

const addPlayerForm = document.getElementById('create-player-form');
if (addPlayerForm) {
  addPlayerForm.addEventListener('submit', e => {
    e.preventDefault();

    const playerData = {};
    const formElements = addPlayerForm.elements;

    for (let element of formElements) {
      if (element.tagName === 'INPUT' || element.tagName === 'SELECT') {
        const value = element.value.trim();
        const name = element.name;

        if (value) {
          playerData[name] = value;
        }
      }
    }

    createPlayer(playerData);
  });
}

const groupOverview = document.getElementById('groups-overview');
if (groupOverview) {
  const removePlayerButtons = document.querySelectorAll('.remove-group-btn');

  removePlayerButtons.forEach(function(button) {
    button.addEventListener('click', function() {
      const groupId = button.getAttribute('data-group-id');

      const confirmDeleteBtn = document.getElementById('confirmDeleteBtn');
      confirmDeleteBtn.addEventListener('click', function() {
        deleteGroup(groupId);
        // Ukrywamy modal po kliknięciu przycisku "Usuń"
        const modalElement = document.getElementById('confirmDeleteModal');
        const modal = bootstrap.Modal.getInstance(modalElement);
        modal.hide();
      });
    });
  });
}

const inviteCoach = document.getElementById('invite-coach');
if (inviteCoach) {
  inviteCoach.addEventListener('submit', e => {
    e.preventDefault();
    const groupId = inviteCoach.groupId.value;
    const coachId = { coachId: inviteCoach.coachId.value };
    updateGroup(coachId, groupId);
  });
}
const addTraining = document.getElementById('add-training');
if (addTraining) {
  const datePickerEl = document.getElementById('date-picker');
  const starttimePickerEl = document.getElementById('start-time-picker');
  const endtimePickerEl = document.getElementById('end-time-picker');
  const trainingNameEl = document.getElementById('training-name');
  const trainingDescriptionEl = document.getElementById('training-description');
  const groupSelectEl = document.getElementById('group-select');
  const saveButton = document.getElementById('save-button');

  const groupCheckboxes = document.querySelectorAll('input[name="groupId"]');

  const datePicker = flatpickr(document.getElementById('date-picker'), {
    allowInput: true,
    dateFormat: 'm/d/Y',
    mode: 'multiple',
    locale: Polish
  });
  const startTimePicker = flatpickr(
    document.getElementById('start-time-picker'),
    {
      enableTime: true,
      noCalendar: true,
      dateFormat: 'H:i',
      time_24hr: true
    }
  );
  const endTimePicker = flatpickr(document.getElementById('end-time-picker'), {
    enableTime: true,
    noCalendar: true,
    dateFormat: 'H:i',
    time_24hr: true
  });

  saveButton.addEventListener('click', function() {
    const selectedDates = datePicker.selectedDates;
    const startSelectedTime = startTimePicker.selectedDates[0];
    const endSelectedTime = endTimePicker.selectedDates[0];
    const trainingName = trainingNameEl.value.trim();
    const trainingDescription = trainingDescriptionEl.value.trim();
    const selectedGroups = [];

    groupCheckboxes.forEach(checkbox => {
      if (checkbox.checked) {
        selectedGroups.push(checkbox.value);
      }
    });

    // Walidacja, czy wszystkie pola są wypełnione
    if (
      selectedDates.length === 0 ||
      !startSelectedTime ||
      !endSelectedTime ||
      !trainingName ||
      !trainingDescription ||
      selectedGroups.length === 0
    ) {
      alert('Proszę wypełnić wszystkie wymagane pola.');
      return;
    }

    // Sprawdzenie, czy start jest większy od end
    if (startSelectedTime > endSelectedTime) {
      alert(
        'Czas rozpoczęcia treningu nie może być późniejszy niż czas zakończenia!'
      );
      return;
    }

    const trainingData = selectedDates.map(date => {
      const formattedDate = flatpickr.formatDate(date, 'Y-m-d');
      const startFormattedTime = flatpickr.formatDate(startSelectedTime, 'H:i');
      const endFormattedTime = flatpickr.formatDate(endSelectedTime, 'H:i');

      return {
        name: trainingName,
        startDate: formattedDate + 'T' + startFormattedTime + ':00.000Z',
        endDate: formattedDate + 'T' + endFormattedTime + ':00.000Z',
        description: trainingDescription,
        group: selectedGroups
      };
    });

    createTraining(trainingData);
  });
}
const calendarEl = document.getElementById('calendar');
if (calendarEl) {
  // Wyświetl kalendarz
  const calendar = new Calendar(calendarEl, {
    plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay'
    },
    themeSystem: 'bootstrap5',
    plugins: [bootstrap5Plugin],
    timeZone: 'UTC',
    events: function(info, successCallback, failureCallback) {
      const startDate = new Date(info.start);
      const startDateFormatted = startDate.toISOString().split('T')[0];
      const endDate = new Date(info.end);
      const endDateFormatted = endDate.toISOString().split('T')[0];

      fetch(
        `/api/v1/trainings?start=${startDateFormatted}&end=${endDateFormatted}`
      )
        .then(response => response.json())
        .then(data => {
          const trainings = data.data.trainings;
          const events = trainings.map(training => ({
            id: training._id,
            title: training.name,
            start: training.startDate,
            end: training.endDate,
            url: `/trainings/${training._id}` // Adres URL do treningu
          }));
          successCallback(events);
        })
        .catch(err => {
          console.error(err);
          failureCallback(new Error('Error fetching events'));
        });
    },
    eventClick: function(info) {
      // Przekierowanie do strony treningu po kliknięciu na wydarzenie
      window.location.href = info.event.url;
    },
    datesSet: event => {
      //console.log(event.start.getFullYear());
    },
    locale: plLocale
  });

  calendar.render();
}

const attendenceUpdate = document.getElementById('attendence-update');
if (attendenceUpdate) {
  attendenceUpdate.addEventListener('submit', e => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);

    const data = {
      trainingId: formData.get('trainingId'),
      players: []
    };
    const playerIdArray = formData.getAll('playerId[]');
    const statusArray = formData.getAll('status[]');

    for (let i = 0; i < playerIdArray.length; i++) {
      data.players.push({ playerId: playerIdArray[i], status: statusArray[i] });
    }
    attendaceUpdate(data);
  });
}
const descriptionUpdate = document.getElementById('description-update');
if (descriptionUpdate) {
  descriptionUpdate.addEventListener('submit', e => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const id = document.getElementById('training-id').value;
    const data = {
      description: formData.get('description')
    };
    updateTraining(data, id);
  });
}

const uploadImageForm = document.getElementById('uploadImageForm');
if (uploadImageForm) {
  uploadImageForm.addEventListener('submit', function(event) {
    event.preventDefault();

    const fileInput = document.getElementById('formFile');
    const file = fileInput.files[0];
    const id = document.getElementById('player-id').value;
    if (!file) {
      alert('Wybierz plik do przesłania!');
      return;
    }

    // Tworzymy obiekt FormData, aby przesłać plik za pomocą żądania POST
    const formData = new FormData();
    formData.append('image', file);

    uploadPlayerPhoto(formData, id);
  });
}

const forgotPassword = document.getElementById('forgot-password');
if (forgotPassword) {
  forgotPassword.addEventListener('submit', event => {
    event.preventDefault();
    const email = document.getElementById('forgot-password-email').value;
    resetPassword(email);
  });
}
const resetPasswordForm = document.getElementById('reset-password-form');
if (resetPasswordForm) {
  const resetToken = window.location.pathname.split('/').pop();
  resetPasswordForm.addEventListener('submit', function(event) {
    event.preventDefault();
    const password = document.getElementById('password').value;
    const passwordConfirm = document.getElementById('passwordConfirm').value;
    changePassword(password, passwordConfirm, resetToken);
  });
}

const signupForm = document.getElementById('signup-form');
if (signupForm) {
  signupForm.addEventListener('submit', e => {
    e.preventDefault();
    const registerUsername = document.getElementById('register-username').value;
    const registerUsersurname = document.getElementById('register-usersurname')
      .value;
    const registerEmail = document.getElementById('register-email').value;
    const registerPassword = document.getElementById('register-password').value;
    const confirmPassword = document.getElementById('confirm-password').value;
    signup(
      registerUsername,
      registerUsersurname,
      registerEmail,
      registerPassword,
      confirmPassword
    );
  });
}

const markAllPresentButton = document.getElementById('mark-all-present');
if (markAllPresentButton) {
  markAllPresentButton.addEventListener('click', function(event) {
    event.preventDefault();
    const statusSelects = document.querySelectorAll('select[name="status[]"]');
    statusSelects.forEach(select => {
      select.value = 'obecny';
    });
  });
}

const ctx = document.getElementById('attendanceChart');
if (ctx) {
  document.addEventListener('DOMContentLoaded', function() {
    async function generateAttendanceChart() {
      const playerId = window.location.pathname.split('/').pop();
      function formatDateToYMD(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Miesiące są numerowane od 0 do 11, więc dodajemy 1 i formatujemy na dwie cyfry
        const day = String(date.getDate()).padStart(2, '0'); // Dni formatujemy na dwie cyfry

        return `${year}-${month}-${day}`;
      }

      const currentDate = new Date();
      const sixMonthsAgo = new Date();
      sixMonthsAgo.setMonth(currentDate.getMonth() - 6);

      const currentDateYMD = formatDateToYMD(currentDate);
      const sixMonthsAgoYMD = formatDateToYMD(sixMonthsAgo);
      try {
        const response = await fetch(
          `/api/v1/players/attendence/player/detail/${playerId}/${currentDateYMD}/${sixMonthsAgoYMD}`
        );
        const data = await response.json();
        console.log(data)
        // wykres kołowy dla całego okresu
        const chartData = {
          labels: ['Obecny', 'Nieobecny'],
          datasets: [
            {
              data: [data.report.totalObecny, data.report.totalNieobecny],
              backgroundColor: ['green', 'red']
            }
          ]
        };

        const chartOptions = {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            title: {
              display: true,
              text: 'Obecność całkowita'
            }
          }
        };

        new Chart(ctx, {
          type: 'doughnut',
          data: chartData,
          options: chartOptions
        });
        // Wykres dla całego roku szczegółowy
        const totalReportChart = document.getElementById('totalReportChart');
        const statusColors = {
          obecny: 'green',
          nieobecny: 'red',
          usprawiedliwiony: 'yellow',
          zwolniony: 'orange',
          kontuzjowany: 'blue',
          naObozie: 'brown'
          // Dodaj inne kolory dla pozostałych statusów
        };
        const statusLabels = Object.keys(data.report.statusCounts);
        const statusData = Object.values(data.report.statusCounts);
        const backgroundColors = statusLabels.map(
          status => statusColors[status]
        );

        const statusCountsData = {
          labels: statusLabels,
          datasets: [
            {
              data: statusData,
              backgroundColor: backgroundColors
            }
          ]
        };

        const statusCountsOptions = {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              display: true // Ukryj osie X
            },
            y: {
              beginAtZero: true,
              ticks: {
                precision: 0,
                stepSize: 1
              },
              title: {
                display: true,
                text: 'Liczba treningów'
              }
            }
          },
          plugins: {
            title: {
              display: true,
              text: 'Obecność całkowita z podziałem na status'
            },
            legend: {
              display: false,
              position: 'top', // Pozycja legendy
              labels: {
                usePointStyle: true // Wykorzystaj kształt punktu (kolor statusu)
              }
            }
          }
        };

        new Chart(totalReportChart, {
          type: 'bar',
          data: statusCountsData,
          options: statusCountsOptions
        });
        // Generowanie wykresów słupkowych szczególowych dla raportu miesięcznego
        const monthlyCtx = document.getElementById('monthlyReportChart');

        const months = Object.keys(data.report.monthlyReport);
        const monthLabels = months.map(month => month.substring(0, 3)); // Skróć nazwy miesięcy do trzech liter

        const monthlyData = {
          labels: monthLabels,
          datasets: statusLabels.map(status => ({
            label: status,
            data: months.map(
              month => data.report.monthlyReport[month].statusCounts[status]
            ),
            backgroundColor: Array(months.length).fill(
              statusColors[status] || 'gray'
            )
          }))
        };

        const monthlyOptions = {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                precision: 0,
                stepSize: 1
              },
              title: {
                display: true,
                text: 'Liczba treningów'
              }
            }
          },
          plugins: {
            title: {
              display: true,
              text: 'Obecność w poszczególnych miesiącach z podziałem na status'
            }
          }
        };

        new Chart(monthlyCtx, {
          type: 'bar',
          data: monthlyData,
          options: monthlyOptions
        });
        // generowanie wykresów słupkowych dla poszczegolnych miesięcy - obecny/ nieobecny
        const totalMonthlyCtx = document.getElementById('totalMonthlyChart');
        if (totalMonthlyCtx) {
          const months = Object.keys(data.report.monthlyReport);
          const monthLabels = months.map(month => month.substring(0, 3)); // Skróć nazwy miesięcy do trzech liter

          const totalObecnyData = months.map(
            month => data.report.monthlyReport[month].totalObecny
          );
          const totalNieobecnyData = months.map(
            month => data.report.monthlyReport[month].totalNieobecny
          );

          const totalMonthlyData = {
            labels: monthLabels,
            datasets: [
              {
                label: 'Total Obecny',
                data: totalObecnyData,
                backgroundColor: 'green'
              },
              {
                label: 'Total Nieobecny',
                data: totalNieobecnyData,
                backgroundColor: 'red'
              }
            ]
          };

          const totalMonthlyOptions = {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              y: {
                beginAtZero: true,
                ticks: {
                  precision: 0,
                  stepSize: 1
                },
                title: {
                  display: true,
                  text: 'Liczba treningów'
                }
              }
            },
            plugins: {
              title: {
                display: true,
                text: 'Obecność całkowita w poszczególnym miesiącu'
              }
            }
          };

          new Chart(totalMonthlyCtx, {
            type: 'bar',
            data: totalMonthlyData,
            options: totalMonthlyOptions
          });
        }
        //generowania podglądu w kalendarzu:
        const calendarEl = document.getElementById('calendar-report');

        const events = Object.keys(data.report.dailyReport).map(date => ({
          title: data.report.dailyReport[date],
          start: new Date(date),
          end: new Date(new Date(date).getTime() + 2 * 60 * 60 * 1000) // Trening trwa 2 godziny
        }));

        const calendar = new Calendar(calendarEl, {
          plugins: [dayGridPlugin, interactionPlugin, listPlugin],
          headerToolbar: {
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,listWeek'
          },
          themeSystem: 'bootstrap5',
          plugins: [bootstrap5Plugin],
          timeZone: 'UTC',
          events: events,
          locale: plLocale
        });

        calendar.render();
      } catch (error) {
        console.error('Błąd podczas pobierania danych:', error);
      }
    }

    generateAttendanceChart();
  });
}

const findChildForm = document.getElementById('find-child-form');
if (findChildForm) {
  findChildForm.addEventListener('submit', e => {
    e.preventDefault();

    const playerData = {};
    const formElements = findChildForm.elements;

    for (let element of formElements) {
      if (element.tagName === 'INPUT' || element.tagName === 'SELECT') {
        const value = element.value.trim();
        const name = element.name;

        if (value) {
          playerData[name] = value;
        }
      }
    }

    addChild(playerData);
  });
}

const playerSearchInput = document.getElementById('autoComplete');
if (playerSearchInput) {
  const autoCompleteJS = new autoComplete({
    selector: '#autoComplete',
    placeHolder: 'Wyszukaj zawodnika',
    data: {
      src: async () => {
        try {
          // Loading placeholder text
          playerSearchInput.setAttribute('placeholder', 'Loading...');

          // Fetch External Data Source
          const source = await fetch('/api/v1/players/search/player');
          const data = await source.json();

          // Post Loading placeholder text
          playerSearchInput.setAttribute(
            'placeholder',
            autoCompleteJS.placeHolder
          );

          // Returns Fetched data
          return data.players;
        } catch (error) {
          return error;
        }
      },
      keys: ['name', 'surName'],
      cache: true
    },
    resultsList: {
      element: (list, data) => {
        const info = document.createElement('p');
        if (data.results.length > 0) {
          info.innerHTML = `Displaying <strong>${
            data.results.length
          }</strong> out of <strong>${data.matches.length}</strong> results`;
        } else {
          info.innerHTML = `Found <strong>${
            data.matches.length
          }</strong> matching results for <strong>"${data.query}"</strong>`;
        }
        list.prepend(info);
      },
      noResults: true,
      maxResults: 15,
      tabSelect: true
    },
    resultItem: {
      element: (item, data) => {
        // Modify Results Item Style
        item.style = 'display: flex; justify-content: space-between;';
        // Modify Results Item Content
        item.innerHTML = `
        <span style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden;">
          ${data.value.name} ${data.value.surName}
        </span>
        <span style="display: flex; align-items: center; font-size: 13px; font-weight: 100; text-transform: uppercase; color: rgba(0,0,0,.2);">
          ${data.key}
        </span>`;
      },
      highlight: true
    },
    events: {
      input: {
        focus: () => {
          if (autoCompleteJS.input.value.length) autoCompleteJS.start();
        }
      },
      result: {
        click: (event, data) => {
          const playerId = data.selection.value.id;
          window.location.href = `/player/${playerId}`;
        }
      }
    }
  });
  autoCompleteJS.input.addEventListener('selection', function(event) {
    const feedback = event.detail;
    autoCompleteJS.input.blur();

    // Pobierz ID z wybranego wyniku
    const playerId = feedback.selection.value.id;

    // Przekieruj do strony z ID zawodnika
    window.location.href = `/player/${playerId}`;
  });
}

const deleteTrainingButton = document.getElementById('training-delete-button');
if (deleteTrainingButton) {
  deleteTrainingButton.addEventListener('click', function() {
    const trainingId = deleteTrainingButton.getAttribute('data-training-id');
    const confirmDeleteBtn = document.getElementById('confirmDeleteBtn');
    confirmDeleteBtn.addEventListener('click', function() {
      deleteTraining(trainingId);

      // Ukrywamy modal po kliknięciu przycisku "Usuń"
      const modalElement = document.getElementById('confirmDeleteModal');
      const modal = bootstrap.Modal.getInstance(modalElement);
      modal.hide();
    });
  });
}
const generateReport = document.getElementById('generate-report');
if (generateReport) {
  document.getElementById('downloadPDF').setAttribute("disabled", "");
  document.getElementById('sendPdfButton').setAttribute("disabled", "");
  const datepickerEl = document.getElementById('date-picker');
  const monthpickerEl = document.getElementById('month-picker');
  const datePicker = flatpickr(datepickerEl, {
    dateFormat: 'Y-m-d',
    mode: 'range',
    locale: Polish
  });
  const monthPicker = flatpickr(monthpickerEl, {
    locale: Polish,
    mode: 'single',
    plugins: [
      new monthSelectPlugin({
        shorthand: true, //defaults to false
        dateFormat: 'Y-m-d', //defaults to "F Y"
        altFormat: 'F Y', //defaults to "F Y"
        
      })
    ]
  });
  const customDateRadio = document.getElementById('custom-date-radio');
  const monthDateRadio = document.getElementById('month-date-radio');

  customDateRadio.addEventListener('change', function() {
    datepickerEl.disabled = false;
    monthpickerEl.disabled = true;
  });
  
  monthDateRadio.addEventListener('change', function() {
    monthpickerEl.disabled = false;
    datepickerEl.disabled = true;
  });
  const chooseData = document.getElementById('generate-report');
  chooseData.addEventListener('submit', e => {
    e.preventDefault();

    let groupId = document.getElementById('groupSelect').value;
    let dateRange = datePicker.selectedDates;
    let monthRange = monthPicker.selectedDates;

    let toDateSend = '';
    let fromDateSend = '';
    if (customDateRadio.checked) {
      let dateRangeFrom = new Date(dateRange[0]);
      let dateRangeTo = new Date(dateRange[1]);
      dateRangeTo.setDate(dateRangeTo.getDate() + 2);
      dateRangeFrom.setDate(dateRangeFrom.getDate() + 1);
      let formattedFromDate = dateRangeFrom.toISOString().slice(0, 10);
      let formattedToDate = dateRangeTo.toISOString().slice(0, 10);
      toDateSend = formattedToDate;
      fromDateSend = formattedFromDate;
    } else if (monthDateRadio.checked) {
      let monthDate = monthRange[0];
      let monthRangeFrom = new Date(monthDate);
      monthRangeFrom.setDate(monthRangeFrom.getDate() + 1);
      let monthRangeTo = new Date(monthRangeFrom);
      monthRangeTo.setMonth(monthRangeTo.getMonth() + 1);

      let formattedMonthFrom = monthRangeFrom.toISOString().slice(0, 10);
      let formattedMonthTo = monthRangeTo.toISOString().slice(0, 10);
      toDateSend = formattedMonthTo;
      fromDateSend = formattedMonthFrom;
    }

    axios
      .get(
        `api/v1/groups/attendence/group/detail/${groupId}/${toDateSend}/${fromDateSend}`
      )
      .then(function(response) {
        if (response.data.status === 'success') {
          generateGroupReport(response);
          document.getElementById('downloadPDF').removeAttribute("disabled");
          document.getElementById('sendPdfButton').removeAttribute("disabled");
        } else {
          showAlert('error', response.data.message);
        }
      })
      .catch(function(error) {
        if (error.response) {
          // Obsługa błędów, jeśli dostępne jest error.response
          showAlert('error', error.response.data.message);
        } else if (error.message) {
          // Obsługa błędów, jeśli message jest dostępne
          showAlert('error', error.message);
        } else {
          // Obsługa pozostałych błędów
          showAlert('error', 'Wystąpił nieznany błąd.');
        }
      });
  });
}

const donwloadPdf = document.getElementById('downloadPDF');


if (donwloadPdf) {
  donwloadPdf.addEventListener('click', e => {
    generatePDF()
  });
  document.getElementById('sendPdfReport').addEventListener('submit', e => {
    e.preventDefault();
    const email = document.getElementById('email-address').value;
    sendPDF(email);
  })
}
