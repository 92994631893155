/* eslint-disable */
import axios from 'axios';
import { showAlert } from './alerts';

export const updatePlayer = async (data, id) => {
  try {
    const res = await axios.patch(`/api/v1/players/${id}`, data);
    if (res.data.status === 'success') {
      showAlert('success', 'Zaktualizowano dane');
      window.setTimeout(() => {
        location.reload(true);
      }, 1500);
    }
  } catch (err) {
    showAlert('error', err.response);
    window.setTimeout(() => {
      location.reload(true);
    }, 1500);
  }
};


export const createPlayer = async data => {
  try {
    const res = await axios.post(`/api/v1/players/`, data);
    if (res.data.status === 'success') {
      showAlert('success', 'Poprawnie dodano zawodnika');
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
    window.setTimeout(() => {
      location.reload(true);
    }, 2500);
  }
};


export const deletePlayer = async id => {
  try {
    const res = await axios.delete(`/api/v1/players/${id}`);
    if (res.status === 204) {
      showAlert('success', 'Poprawnie usunięto zawodnika');
      window.setTimeout(() => {
        location.reload(true);
      }, 1500);
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
    window.setTimeout(() => {
      location.reload(true);
    }, 1500);
  }
};


export const attendaceUpdate = async data => {
  try {
    const res = await axios.patch(`/api/v1/players/attendence`, data);
    if (res.data.status === 'success') {
      showAlert('success', 'Poprawnie zmodyfikowano obecność');
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
    window.setTimeout(() => {
      location.reload(true);
    }, 1500);
  }
};


export const uploadPlayerPhoto = async (data, id) => {
  try {
    const res = await axios.patch(`/api/v1/players/${id}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }});
    if (res.data.status === 'success') {
      showAlert('success', 'Zaktualizowano dane');
      window.setTimeout(() => {
        location.reload(true);
      }, 1500);
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
    window.setTimeout(() => {
      location.reload(true);
    }, 1500);
  }
};


export const addChild = async data => {
  try {
    const res = await axios.patch(`/api/v1/players/search/child`, data);
    if (res.data.status === 'success') {
      showAlert('success', 'Znaleziono dziecko');
      window.setTimeout(() => {
        location.assign(`/player/${res.data.player}`);
      }, 1500);
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};
