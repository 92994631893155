/* eslint-disable */

import axios from 'axios';
import { showAlert } from './alerts';
// export const login = async (email, password) => {
//   try {
//     const res = await axios({
//       method: 'POST',
//       url: '/api/v1/users/login',
//       data: {
//         email,
//         password
//       }
//     });
//     if (res.data.status === 'success') {
//       showAlert('success', 'Zalogowano');
//       window.setTimeout(() => {
//         location.assign('/');
//       }, 1500);
//     }
//   } catch (err) {
//     showAlert('error', err.response.data.message);
//   }
// };

export const login = async (email, password) => {
  try {
    const res = await axios.post('/api/v1/users/login', { email, password },{
      headers: {
        'Content-Type': 'application/json'
      }});
    if (res.data.status === 'success') {
      showAlert('success', 'Zalogowano');
      window.setTimeout(() => {
        location.assign('/');
      }, 1500);
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

// export const logout = async () => {
//   try {
//     const res = await axios({
//       method: 'GET',
//       url: '/api/v1/users/logout'
//     });
//     if (res.data.status === 'success') location.assign('/');
//   } catch (err) {
//     showAlert('error', 'Error logging out! Try again');
//   }
// };

export const logout = async () => {
  try {
    const res = await axios.get('/api/v1/users/logout');
    if (res.data.status === 'success') location.assign('/');
  } catch (err) {
    showAlert('error', 'Error logging out! Try again');
  }
};

// export const resetPassword = async email => {
//   try {
//     const res = await axios({
//       method: 'POST',
//       url: '/api/v1/users/forgotpassword',
//       data: {
//         email
//       }
//     });
//     if (res.data.status === 'success') {
//       showAlert('success', 'Na podany email wysłano link do zmiany hasła');
//     }
//   } catch (err) {
//     showAlert('error', err.response.data.message);
//   }
// };

export const resetPassword = async email => {
  try {
    const res = await axios.post('/api/v1/users/forgotpassword', {
      email
    });
    if (res.data.status === 'success') {
      showAlert('success', 'Na podany email wysłano link do zmiany hasła');
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

// export const changePassword = async (password, passwordConfirm, token) => {
//   try {
//     const res = await axios({
//       method: 'PATCH',
//       url: `/api/v1/users/resetpassword/${token}`,
//       data: {
//         password,
//         passwordConfirm
//       }
//     });

//     if (res.data.status === 'success') {
//       showAlert('success', 'Hasło zostało zmienione');
//       window.setTimeout(() => {
//         location.assign('/');
//       }, 1500);
//     }
//   } catch (err) {
//     showAlert('error', err.response.data.message);
//   }
// };

export const changePassword = async (password, passwordConfirm, token) => {
  try {
    const res = await axios.patch(`/api/v1/users/resetpassword/${token}`, {
      password,
      passwordConfirm
    });

    if (res.data.status === 'success') {
      showAlert('success', 'Hasło zostało zmienione');
      window.setTimeout(() => {
        location.assign('/');
      }, 1500);
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

// export const signup = async (
//   name,
//   surName,
//   email,
//   password,
//   passwordConfirm
// ) => {
//   try {
//     const res = await axios({
//       method: 'POST',
//       url: '/api/v1/users/signup',
//       data: {
//         name,
//         surName,
//         email,
//         password,
//         passwordConfirm
//       }
//     });
//     if (res.data.status === 'success') {
//       showAlert('success', 'Utworzono konto użytkownik');
//       window.setTimeout(() => {
//         location.assign('/');
//       }, 1500);
//     }
//   } catch (err) {
//     showAlert('error', err.response.data.message);
//   }
// };

export const signup = async (
  name,
  surName,
  email,
  password,
  passwordConfirm
) => {
  try {
    const res = await axios.post('/api/v1/users/signup', {
      name,
      surName,
      email,
      password,
      passwordConfirm
    });
    if (res.data.status === 'success') {
      showAlert('success', 'Utworzono konto użytkownik');
      window.setTimeout(() => {
        location.assign('/');
      }, 1500);
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};
